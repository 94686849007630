import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div id="horizon">
    <div id="content">
        <div class="bodytext">
          <table height="100%"  border="0" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="208" rowspan="4"><h5>&nbsp;</h5></td>
                    <td height="95" colspan="2" valign="bottom" class="style9"><a href="http://www.4elementsmusic.com" target="_blank">Go to Searchable Library at 4ElementsMusic.com</a></td>
                  </tr>
                  <tr>
                    <td height="95" colspan="2" valign="bottom" class="style3">Welcome to 8118Music.com</td>
                  </tr>
                  <tr>
                    <td height="200" colspan="2" valign="top" class="style1">
                      <p>
                        The &ldquo;right&rdquo; piece of music. The perfect underscore, sting or bumper. 
                        Something understated, obvious, driving or ethereal. 
                        Small screen friendly, or slightly cinematic, humorous or somber, 
                        a theme to remember or a subtle bed&hellip;all at 8118. 
                        Don&rsquo;t waste your time hunting down something &ldquo;close&rdquo;. 
                      </p>
                    <p>Make a change. Make a call or send an email and get precisely the music you need&hellip;delivered&hellip;to your edit suite or your email inbox, today or next week. Pay only for what you use&hellip;spend your resources on your creative process.. The legwork is on us.</p>                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" valign="top" class="style1">
                     </td>
                  </tr>

                  <tr>
                    <td height="70" colspan="3" valign="top"><table width="100%"  border="0" cellspacing="0" cellpadding="0">
                       © {new Date().getFullYear()}, 8118 Music Inc.
                    </table></td>
                  </tr>
                </table> 
          </div>
      </div>
      </div>
  </Layout>
)

export default IndexPage
